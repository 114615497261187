// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BookingList_counterContainer__PrlpN {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 8px;
    margin: 16px 0;
    background-color: #f9f9f9;
  }
  
  .BookingList_counterLabel__Xot-Q {
    margin-left: 16px;
    font-weight: 600;
  }
  
  .BookingList_counterButton__\\+jUfA {
    min-width: 32px;
    margin: 0 8px;
  }
  
  .BookingList_counterValue__ysuHl {
    margin: 0 16px;
    min-width: 32px;
    text-align: center;
    font-weight: 500;
  }
  .BookingList_priceBox__cedL2 {
    display: flex;
    align-items: center;
    padding: 4px;
    background-color: #ffff;
    color: black;
    border-radius: 4px;
    margin-left: 16px;
  }
  
  .BookingList_totalBox__g1-TN {
    display: flex;
    align-items: center;
    color: black;
    padding: 2px;
    background-color: #ffff;
    border-radius: 4px;
    margin-left: 16px
  }
  
  .BookingList_totalBox__g1-TN > * {
    margin-top: 8px;
  }
    `, "",{"version":3,"sources":["webpack://./src/pages/BookingList.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;IACZ,cAAc;IACd,yBAAyB;EAC3B;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,aAAa;EACf;;EAEA;IACE,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;EAClB;EACA;IACE,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,uBAAuB;IACvB,kBAAkB;IAClB;EACF;;EAEA;IACE,eAAe;EACjB","sourcesContent":[".counterContainer {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    padding: 8px;\n    margin: 16px 0;\n    background-color: #f9f9f9;\n  }\n  \n  .counterLabel {\n    margin-left: 16px;\n    font-weight: 600;\n  }\n  \n  .counterButton {\n    min-width: 32px;\n    margin: 0 8px;\n  }\n  \n  .counterValue {\n    margin: 0 16px;\n    min-width: 32px;\n    text-align: center;\n    font-weight: 500;\n  }\n  .priceBox {\n    display: flex;\n    align-items: center;\n    padding: 4px;\n    background-color: #ffff;\n    color: black;\n    border-radius: 4px;\n    margin-left: 16px;\n  }\n  \n  .totalBox {\n    display: flex;\n    align-items: center;\n    color: black;\n    padding: 2px;\n    background-color: #ffff;\n    border-radius: 4px;\n    margin-left: 16px\n  }\n  \n  .totalBox > * {\n    margin-top: 8px;\n  }\n    "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"counterContainer": `BookingList_counterContainer__PrlpN`,
	"counterLabel": `BookingList_counterLabel__Xot-Q`,
	"counterButton": `BookingList_counterButton__+jUfA`,
	"counterValue": `BookingList_counterValue__ysuHl`,
	"priceBox": `BookingList_priceBox__cedL2`,
	"totalBox": `BookingList_totalBox__g1-TN`
};
export default ___CSS_LOADER_EXPORT___;
