// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` .PlanCard_card__iUK2p {
    position: relative !important;
    transition: filter 0.3s, opacity 0.3s !important;
  } 
  
  .PlanCard_temporaryClosed__EBuuc {
    filter: grayscale(50%);
    opacity: 0.7;
  }
  
  .PlanCard_closed__4XpRG {
    filter: grayscale(100%);
    opacity: 0.5;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/PlanCard.module.css"],"names":[],"mappings":"CAAC;IACG,6BAA6B;IAC7B,gDAAgD;EAClD;;EAEA;IACE,sBAAsB;IACtB,YAAY;EACd;;EAEA;IACE,uBAAuB;IACvB,YAAY;EACd","sourcesContent":[" .card {\n    position: relative !important;\n    transition: filter 0.3s, opacity 0.3s !important;\n  } \n  \n  .temporaryClosed {\n    filter: grayscale(50%);\n    opacity: 0.7;\n  }\n  \n  .closed {\n    filter: grayscale(100%);\n    opacity: 0.5;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `PlanCard_card__iUK2p`,
	"temporaryClosed": `PlanCard_temporaryClosed__EBuuc`,
	"closed": `PlanCard_closed__4XpRG`
};
export default ___CSS_LOADER_EXPORT___;
