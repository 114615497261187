// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PlanStatusSelect_statusSelect__T0QJo {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 150px;
}`, "",{"version":3,"sources":["webpack://./src/components/PlanStatusSelect.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,YAAY;AACd","sourcesContent":[".statusSelect {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  width: 150px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statusSelect": `PlanStatusSelect_statusSelect__T0QJo`
};
export default ___CSS_LOADER_EXPORT___;
